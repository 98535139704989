<template>
  <div class="ml-4 mr-4 mt-4">
    <div class="forms" v-loading="loaders">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Name"
                  label="Name*" v-model="request.dynamicSectionItemForm.name"
                  placeholder=" Name">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Category</label>
                <el-select
                  class="w-100"
                  v-model="request.dynamicSectionItemForm.category"
                  placeholder="Select Category"
                  @change="onChangeDynamicSectionCategory"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.categories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Sub Category</label>
                <el-select
                  class="w-100 mb-3"
                  v-model="request.dynamicSectionItemForm.subCategory"
                  placeholder="Select Sub Category"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Sub Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.subCategories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    v-model="request.dynamicSectionItemForm.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-6 row m-0 mb-4">
                <label class="form-control-label w-100">PDF</label>
                <div class="col-md-6 pl-0 pr-2">
                  <div class="radio-button-card pl-3">
                    <base-radio name="v1" v-model="selectedValue" value="pdfValues.r1.value" class="pt-3 base-radio-button"
                                selected>
                      <span class="base-radio-text">Yes</span>
                    </base-radio>
                  </div>
                </div>
                <div class="col-md-6 pr-0 pl-2">
                  <div class="radio-button-card pl-3">
                    <base-radio name="v2" v-model="selectedValue" value="pdfValues.r2.value" class="pt-3 base-radio-button">
                      <span class="base-radio-text">No</span>
                    </base-radio>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center" v-if="selectedValue=='v2'">
                <div class="img-upload" :class="{'w-100':!imageExist}">
                  <label class="form-control-label" for="profileImage">Picture</label>
                  <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                         class="form-control file-upload" @change="onImageChange">
                  <span class="font-size-12px">Recommended image size 500x300 px. Must be less than 500kb.</span>
                </div>
                <el-image
                  v-loading="request.loaders.image"
                  class="img-size pl-2"
                  :class="{'d-none':!imageExist}"
                  :src="request.dynamicSectionItemForm.imagePreview"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>
              <div class="col-lg-6 col-md-6" v-if="selectedValue=='v1'">
                <label class="form-control-label" for="PDF">PDF</label>
                <input type="file" id="pdf" ref="pdf" accept="application/pdf" class="form-control file-upload"
                       @change="onFileChange">
                <span class="font-size-12px">File size should be 20Mb or less.</span>
              </div>
            </div>
            <div class="row mt-3" v-if="selectedValue=='v2'">
              <div class="col equal-height-columns">
                <label class="form-control-label">Content*</label>
                <editor
                  v-model="request.dynamicSectionItemForm.content"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {store} from "@/vuex/store";
import Helper from "@/util/globalHelpers";

export default {
  name: "DynamicContentSectionItemForm",
  props: ['dynamicSectionItemToEdit', 'loaders', 'buttonText', 'dynamicContentSection'],
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        loaders: {
          image: false,
        },
        dynamicSectionItemForm: {
          name: '',
          sectionId: 0,
          category: 0,
          subCategory: 0,
          picture: '',
          imagePreview: '',
          status: 1,
          content: '',
          pdfFile: '',
          pdf: 0,
        },
      },
      response: {
        categories: [],
        subCategories: [],
      },
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      pdfValues: {
        v1: {value: 1, label: 'Yes'},
        v2: {value: 0, label: 'No'},
      },
      selectedValue: 'v2',
      dynamicSection: {
        id: 0,
        name: 'Dynamic Section'
      }
    }
  },
  watch: {
    /**
     * map onto dynamic section item form
     * @param newVal
     */
    dynamicSectionItemToEdit(newVal) {
      this.request.loaders.image = true
      this.request.dynamicSectionItemForm.name = newVal.name ?? ''
      this.request.dynamicSectionItemForm.sectionId = newVal.dynamic_section_id
      this.request.dynamicSectionItemForm.category = newVal.dynamic_section_category_id
      this.request.dynamicSectionItemForm.subCategory = newVal.dynamic_section_sub_category_id
      this.request.dynamicSectionItemForm.content = newVal.content ?? ''
      this.request.dynamicSectionItemForm.status = newVal.status
      this.request.dynamicSectionItemForm.pdf = newVal.is_pdf
      this.selectedValue = newVal.is_pdf == 1 ? 'v1' : 'v2'
      if (newVal.hasOwnProperty('dynamic_section_category')) {
        this.fetchSubCategories(newVal.dynamic_section_category_id)
      }
      this.request.dynamicSectionItemForm.imagePreview = newVal.picture
    },

    /**
     * Update dynamic content section and get categories
     * @param newVal
     */
    dynamicContentSection(newVal) {
      this.dynamicContentSection.id = newVal.id
      this.dynamicContentSection.name = newVal.name
      this.request.dynamicSectionItemForm.sectionId = newVal.id
      this.fetchCategories()
    }
  },
  methods: {
    /**
     * Handle event on file change
     * @param e - Event object
     */
    onFileChange(e) {
      if (e.target.files[0].size > 20000000) {
        this.$notify.error({
          title: 'Error',
          message: 'The pdf file size can not be greater than 20Mb.'
        })
        this.$refs.pdf.value = null
        return
      }
      this.request.dynamicSectionItemForm.pdfFile = e.target.files[0];
    },

    /**
     * Handle event on image change
     * @param e - Event object
     */
    onImageChange(e) {
      if (e.target.files[0].size > 500000) {
        this.$notify.error({
          title: 'Error',
          message: 'The image size must be less than 500kb.'
        })
        this.$refs.profileImage.value = null
        return
      }
      this.request.dynamicSectionItemForm.picture = e.target.files[0]
    },

    /**
     * Handle event for key press in editor
     * @param value
     */
    handleKeyPressForEditor(value) {
      this.request.dynamicSectionItemForm.content = value;
    },

    /**
     * Handle event on changing dynamic section category
     * @param dynamicSectionCategory
     */
    onChangeDynamicSectionCategory(dynamicSectionCategory) {
      if (dynamicSectionCategory == 0) {
        this.response.subCategories = [];
        this.request.dynamicSectionItemForm.subCategory = 0;
      } else {
        this.request.dynamicSectionItemForm.subCategory = 0;
        this.fetchSubCategories(dynamicSectionCategory);
      }
    },

    /**
     * Fetch categories for requested dynamic section
     */
    fetchCategories() {
      let vm = this
      const params = {
        sectionId: vm.dynamicContentSection.id
      }
      vm.$store.dispatch('dynamicContentSectionModule/_getAllDynamicSectionCategories', params)
        .then(response => {
          vm.response.categories = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Category',
            message: message
          })
        })
    },

    /**
     * Fetch sub categories for requested category
     * @param id
     */
    fetchSubCategories(id) {
      let vm = this;
      if (id) {
        const params = { categoryId: id }
        vm.$store.dispatch('dynamicContentSectionModule/_getDynamicSectionSubCategoriesByCategoryId', params)
          .then(response => {
            vm.response.subCategories = response.data.data
          })
          .catch(error => {
            const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.$notify.error({
              title: vm.dynamicContentSection.name + ' Sub Category',
              message: message
            })
          })
      }
    },

    /**
     * Handle submit event
     * @returns {Promise<void>}
     */
    async onSubmit() {
      const isDynamicSectionItemFormValid = await this.$refs['formValidator'].validate();

      if (isDynamicSectionItemFormValid) {
        this.request.dynamicSectionItemForm.content = Helper.addCustomStylingToContent(this.request.dynamicSectionItemForm.content)
        if (this.$route.name === 'StoreDynamicContentSectionItem') {
          this.request.dynamicSectionItemForm.pdf = this.pdfValues[this.selectedValue].value;
          this.$emit('StoreDynamicContentSectionItem', this.request.dynamicSectionItemForm);
        } else if (this.$route.name === 'EditDynamicContentSectionItem') {
          this.request.dynamicSectionItemForm.pdf = this.pdfValues[this.selectedValue].value;
          this.$emit('updateDynamicContentSectionItem', this.request.dynamicSectionItemForm);
        } else {
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    /**
     * Handle image load event
     */
    handleLoad() {
      this.request.loaders.image = false
    }
  },
  computed: {
    /**
     * Check for image existence
     * @returns {false|string|boolean}
     */
    imageExist() {
      return this.request.dynamicSectionItemForm.imagePreview !== ''
        && this.request.dynamicSectionItemForm.imagePreview
        && this.request.dynamicSectionItemForm.picture === ''
    }
  },
}
</script>

<style scoped>
.img-size {
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload {
  width: calc(100% - 120px);
  display: inline-block;
}

.form-control.file-upload {
  height: calc(1.5em + 1.25rem + 13px);
  padding-top: calc(0.75rem + 3px);
}

.radio-button-card {
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.base-radio-text {
  font: normal normal normal 14px/19px Open Sans;
  color: #525F7F;
}
</style>
