<template>
  <div>
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="pl-4 pr-4 pt-4">
      <h1 class="">Add New {{ dynamicContentSection.name }}</h1>
    </div>
    <dynamic-content-section-item-form
      button-text="Save"
      @StoreDynamicContentSectionItem="StoreDynamicContentSectionItem"
      :dynamic-content-section="dynamicContentSection"
      :loaders="loaders"
    />
  </div>
</template>

<script>
import DynamicContentSectionItemForm from "./DynamicContentSectionItemForm"
import BackButton from "@/components/Router/BackButton"
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin"

export default {
  name: "StoreDynamicContentSectionItem",
  components: {
    DynamicContentSectionItemForm,
    BackButton,
  },
  data() {
    return {
      loaders: false,
      sectionId: this.$route.params.sectionId,
      dynamicContentSection: {
        name: 'Dynamic Section',
        id: 0,
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  mounted() {
    this.assignDynamicSectionObject()
    if (this.dynamicContentSection.name == '') this.getDynamicContentSection()
  },
  methods: {
    /**
     * Assign the dynamic section object
     */
    assignDynamicSectionObject() {
      this.dynamicContentSection = {
        id: this.$route.params.sectionId ?? 0,
        name: this.$route.params.sectionName ?? ''
      }
    },

    /**
     * Get request dynamic content section detail
     */
    getDynamicContentSection() {
      let vm = this
      vm.loaders = true
      const params = {id: this.sectionId}

      vm.$store.dispatch('dynamicContentSectionModule/_getRequestDynamicContentSection', params)
        .then(response => {
          vm.dynamicContentSection = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            message: message
          })
        })
        .finally(() => {
          vm.loaders = false
        })
    },

    /**
     * Store the dynamic content section list item
     * @param event
     * @constructor
     */
    StoreDynamicContentSectionItem(event) {
      let vm = this
      vm.loaders = true

      let formData = new FormData()
      _.each(event, (value, key) => {
        formData.append(key, value)
      })

      const config = {headers: {'content-type': 'multipart/form-data'}}
      const payload = {config, formData}

      vm.$store.dispatch('dynamicContentSectionModule/_storeDynamicSectionListingItem', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' created successfully.'
          })
          vm.$router.back()
        })
        .catch(error => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => vm.loaders = false)
    },
  }
}
</script>

<style scoped>

</style>
