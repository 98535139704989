import { render, staticRenderFns } from "./StoreDynamicContentSectionItem.vue?vue&type=template&id=fdd4d878&scoped=true&"
import script from "./StoreDynamicContentSectionItem.vue?vue&type=script&lang=js&"
export * from "./StoreDynamicContentSectionItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdd4d878",
  null
  
)

export default component.exports